<template>
  <div>
    <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Leads Update	</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/Sales">Leads Update</b-breadcrumb-item>
					<b-breadcrumb-item active>View Leads Details</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
			
			</b-col>
		</b-row>
    <br>
    <b-row>
        <b-col md="8">
            <b-card no-body class="mb-2 p-4 text-center">
							<h3>
                <u class="border-b" style="text-decoration: none;">Leads Details</u>
              </h3>
              <div class="d-flex justify-content-between mt-3">
                  <div>
                      Created By : <span class="text-purple">{{ all_data.created_name }}</span> 
                  </div>
                  
                  <div>
                    Source :  <span class="text-purple"> {{all_data.source_name }}</span> 
                  </div>

                  <div>
                    Followed By : <span class="text-purple"> {{ all_data.follwed_name }}</span> 
                  </div>
              </div>
            </b-card>
        </b-col>
        <b-col md="4">
            <b-card class="mb-2 status_color text-center p-2" >
							<div class="row">
                <div class="col-md-1 border-right-light">
                <i class="cursor-pointer top-side-status"  v-if="all_data.fav==1"  >
        <img :src="require('@/assets/images/hot-icon.svg')" style="width: 12px;">
        </i>
        <i  class="cursor-pointer top-side-status"  v-if="all_data.fav==2"  >
          <img :src="require('@/assets/images/1413121417.svg')" style="width: 12px;">
        </i>
        <i  class="cursor-pointer top-side-status"  v-if="all_data.fav==3"  >
          <img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 21px;margin-left: -4px;">
        </i>
        <i  class="cursor-pointer top-side-status" v-if="all_data.fav==0" >
          <img :src="require('@/assets/images/Exclamation_Point.svg')" style="width: 8px;margin-left: 3px;">
        </i>
      </div>
      <div class="col-md-6 border-right-light">
								<h4>Status : </h4>
                  <h3>
                      <span :class="{'badge':true ,[selected==4 ? 'badge-danger' : 'badge-success' ]:true } ">{{ status_change_value(all_data.status) }}</span>
                      <i class="mdi mdi-pencil-circle cursor-pointer absolute-top-status1"  v-b-modal.modal-3></i>
                  </h3>
                  <b-modal id="modal-3" title="Edit Status" centered header-bg-variant="primary" header-text-variant="light" @ok="status_change" :ok-disabled="status_disable">
                      <b-form-select v-model="selected" :options="options" ></b-form-select>
                  </b-modal>
							</div>
           
            <div class="col-md-5">
              <h4>Expected Launch </h4>
              <i class="mdi mdi-pencil-circle cursor-pointer absolute-top-status"  v-b-modal.modal-4></i>
              <h3> <span class="badge badge-info">{{ expected_date =='1st Jan 1970' || date_format(all_data.expected_date) =='1st Jan 1970' ? 'Not Selected' : date_format(all_data.expected_date) }}</span></h3>
            </div>
          </div>
          <b-modal id="modal-4" title="Expected Launch Date" centered header-bg-variant="primary" header-text-variant="light" 
            ok-title="Updated Date" @ok="Expected_launch_update" :ok-disabled="lanch_disable || expected_date==0">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-overlay :show="lanch_disable" rounded="sm" opacity="0.05">
                <b-form-group id="input-group-2"  label-for="input-2">
                    <label for="input-live">Expected Launch Date:</label>
                    <b-form-input
                        id="input-live"
                        v-model="expected_date"
                        placeholder="Enter Expected Date"
                        trim
                        type="date"
                        :min="today_date()"
                        onkeydown="return false" 
                        
                        >
                    </b-form-input>
                </b-form-group>
              </b-overlay>

              </b-form>
            </b-modal>

            </b-card>
        </b-col>
        <b-col md="8">
            <b-card class="mt-2 mb-3 " style="height:200px">
               <table class="table table-borderless">
                <tr><th colspan="3" ><b class="border-b_">Vendor Details</b> <i class="mdi mdi-pencil-circle cursor-pointer absolute-top fs-25 text-purple"  @click="vendor_edit" ></i></th></tr>
                <tr>
                    <td  style="width:100px">Name  </td> <td style="width:10px">:</td> <td  style="width:400px" > {{ all_data.name }}</td> 
                    <td></td>
                    <td  style="width:200px">Mobile/Landline number  </td>  <td style="width:10px">:</td> 
                    <td style="width:200px" > 
                      <span v-if="all_data.mob && all_data.country_code!='+91'">
                      {{  all_data.mob.substring(0, 4) }} {{  all_data.mob.substring(4, 11) }} 
                    </span>
                    <span v-else>
                      {{ all_data.country_code }} {{  all_data.mob }} 
                    </span>
                    </td>
                </tr>
                <tr>
                    <td   style="width:100px" >Place  </td>  <td style="width:10px">:</td> <td style="width:150px" > {{ all_data.place }}</td>
                    <td></td>
                    <td    style="width:200px">Pincode </td>   <td style="width:10px">:</td> <td  style="width:200px"> {{ all_data.pincode }}</td>
                </tr>
               
                <tr >
                    <td  >Location </td>   <td>:</td> <td colspan="3"> {{ all_data.location }}</td>
                </tr>
               </table>
            </b-card>
        </b-col>
       
        <b-col md="4">
            <b-card class="mt-2 mb-2 anyClass" style="height:200px">
              <table class="table" >
                  <tr><th colspan="3" class="border-top-none"><b class="border-b_">Contact Details</b> <i class="mdi mdi-plus-circle cursor-pointer absolute-top fs-25 text-purple"  @click="MobileAdd" ></i></th></tr>
                    <tr>
                   <th> Person name</th>
                  <th> Mobile Number</th>
                </tr>
              <tbody v-if="all_data.contact_det">
                <tr v-for="(vmb,v) in JSON.parse(all_data.contact_det)" :key="v">
                   <td> {{ vmb.name=='no name' ? 'No Name' : vmb.name }}</td>
                  <td>  
                    <span v-if="vmb.mob && vmb.country_code!='+91'">
                      {{ vmb.mob.substring(0, 4) }} {{  vmb.mob.substring(4, 11) }} 
                    </span>
                    <span v-else>
                      {{ vmb.country_code }} {{ vmb.mob }} 
                    </span>
                    &nbsp; <i class="mdi mdi-pencil cursor-pointer w-75" v-b-modal.modal-mobile @click="editMobile(v)"></i></td>
                </tr>
              </tbody>
              </table>
            </b-card>
<b-modal id="modal-mobile" title="Add Mobile number" centered header-bg-variant="primary" header-text-variant="light" 
ok-title="Add" @ok="newmobile_number" :ok-disabled="mobile_disable">
                <b-form  v-if="show">
    
      
                  <b-overlay :show="mobile_disable" rounded="sm" opacity="0.05">
    <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Contact Name:</label>
        <b-form-input
        id="input-live"
        v-model="$v.contact.name.$model"
        :state="validateState('name')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Contact Name"
        trim
        @focus="nameSelect"
        >
        </b-form-input>
        <b-form-invalid-feedback
          id="pehrs-1-live-feedback"
          v-if="$v.contact.name.$error"
          >
            <div v-if="!$v.contact.name.required">
              Please enter your Name
            </div>
          </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="input-group-2"  label-for="input-2">
      <label for="input-live">Mobile/Landline number:</label>

  <b-input-group >
    <b-input-group-prepend is-text>
      <select style="border:none"  v-model="selecttype">
        <option value="+91">Mobile</option>
        <option value="">Landline</option>
      </select>
    </b-input-group-prepend>

    <b-input-group-prepend is-text>
      {{ selecttype }}
    </b-input-group-prepend>
    <b-form-input
        id="input-live"
        v-model="$v.contact.number.$model"
        :state="validateState('number')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile/Landline number"
        trim
        v-if="selecttype=='+91'"
        >
        </b-form-input>
        <span   v-if="selecttype=='+91'">
        <b-form-invalid-feedback 
          id="pehrs-1-live-feedback"
          v-if="$v.contact.number.$error"
          >
            <div v-if="!$v.contact.number.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.contact.number.minLength ||
              !$v.contact.number.numeric ||
              !$v.contact.number.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
        </span>
        <b-form-input
        id="input-live"
        v-model="$v.contact.landnumber.$model"
        :state="validateState('landnumber')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile/Landline number"
        trim
        v-if="selecttype!='+91'"
        >
        </b-form-input>
        <span   v-if="selecttype!='+91'">
        <b-form-invalid-feedback 
          id="pehrs-1-live-feedback"
          v-if="$v.contact.landnumber.$error"
          >
            <div v-if="!$v.contact.landnumber.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.contact.landnumber.minLength ||
              !$v.contact.landnumber.numeric ||
              !$v.contact.landnumber.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
        </span>
        
  </b-input-group>

      
        
       
    </b-form-group>

    </b-overlay>
   
    </b-form>
            </b-modal>

            <b-modal id="modal-edit" title="Vendor Edit" 
            centered header-bg-variant="primary" header-text-variant="light" @ok="Vendor_update" ok-title="Update Vendor" :ok-disabled="vendor_disable">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
    
      
                  <b-overlay :show="vendor_disable" rounded="sm" opacity="0.05">
    <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Hall Name:</label>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.name.$model"
        :state="validatevendorState('name')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your name"
        trim
        >
        </b-form-input>
    </b-form-group>
    <b-form-group id="input-group-2"  label-for="input-2">
      <label for="input-live">Mobile/Landline number:</label>

      <b-input-group >
    <b-input-group-prepend is-text>
      <select style="border:none"  v-model="selecttype1">
        <option value="+91">Mobile</option>
        <option value="">Landline</option>
      </select>
    </b-input-group-prepend>

    <b-input-group-prepend is-text>
      {{ selecttype1 }}
    </b-input-group-prepend>
    <b-form-input
        id="input-live"
        v-model="$v.vendor.mob.$model"
        :state="validatevendorState('mob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile/Landline number"
        trim
        v-if="selecttype1=='+91'"
        >
        </b-form-input>
        <span   v-if="selecttype1=='+91'">
        <b-form-invalid-feedback 
          id="pehrs-1-live-feedback"
          v-if="$v.vendor.mob.$error"
          >
            <div v-if="!$v.vendor.mob.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.vendor.mob.minLength ||
              !$v.vendor.mob.numeric ||
              !$v.vendor.mob.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
        </span>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.landmob.$model"
        :state="validatevendorState('landmob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile/Landline number"
        trim
        v-if="selecttype1!='+91'"
        >
        </b-form-input>
        <span   v-if="selecttype1!='+91'">
        <b-form-invalid-feedback 
          id="pehrs-1-live-feedback"
          v-if="$v.vendor.landmob.$error"
          >
            <div v-if="!$v.vendor.landmob.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.vendor.landmob.minLength ||
              !$v.vendor.landmob.numeric ||
              !$v.vendor.landmob.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
        </span>
        
  </b-input-group>

      
        
       
    </b-form-group>
   <!--  <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Mobile number:</label> -->
       <!--  <b-form-input
        id="input-live"
        v-model="$v.vendor.mob.$model"
        :state="validatevendorState('mob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your name"
        trim
        >
        </b-form-input> -->


       <!--  <b-input-group prepend="+91" class="mb-2">
        <b-form-input
        id="input-live"
        v-model="$v.vendor.mob.$model"
        :state="validatevendorState('mob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile/Landline number"
        trim
        v-if="selecttype=='+91'"
        >
        </b-form-input>
        
        <b-form-invalid-feedback
          id="pehrs-1-live-feedback"
          v-if="$v.vendor.mob.$error"
          >
            <div v-if="!$v.vendor.mob.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.vendor.mob.minLength ||
              !$v.vendor.mob.numeric ||
              !$v.vendor.mob.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
         
         
       
          </b-input-group>
    </b-form-group> -->

    <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Location:</label>
       <b-form-textarea
            id="textarea"
            v-model="$v.vendor.location.$model"
        :state="validatevendorState('location')"
          
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
        >
        </b-form-textarea>
    </b-form-group>

    
<b-row>
  <b-col md="6">
        <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Place:</label>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.place.$model"
        :state="validatevendorState('place')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your name"
        trim
        >
        </b-form-input>
    </b-form-group>
    </b-col>
    <b-col md="6">
        <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Pincode:</label>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.pincode.$model"
        :state="validatevendorState('pincode')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your name"
        trim
        >
        </b-form-input>
    </b-form-group>
    </b-col>
   
</b-row>

</b-overlay>
  

    

</b-form>
            </b-modal>
        </b-col>
    </b-row>
        <b-row>
            <b-col md="8">
              <b-card class="mb-3 w-100">
                <b-row>
                  <b-col md="4" class="border-left-2 mb-2">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Vendor Plan</span>
                  <h4>{{all_data.vendor_plan}}</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-primary fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
              </div>
            
              
        </b-col>
        <b-col md="4" class="border-left-2 mb-2">
              <div class="d-flex align-items-center" >
                <div class="mr-2">
                 
                </div>
                <div class="mr-2">
                  
                  Vendor value <span v-html="convert_amount(all_data.range)"></span>
                  <h4>{{ all_data.pay_amounts}}</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-warning fs-5">
											<i class="mdi mdi-currency-inr"></i>
										</span>
                  </div>
                </div>
                
              </div>
            
              
        </b-col>
        <b-col md="4">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span> Rupee value </span>
                  <h4> &#8377;{{ numberWithCommas(all_data.amount_of_plan) }} 
                   </h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-primary fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
                <i class="mdi mdi-pencil-circle cursor-pointer absolute-top-1 fs-25 text-purple" @click="hall_other_details" ></i>
              </div>
           </b-col>
        </b-row>
      
          <b-row class="border-top-2">
        <b-col md="4" class="border-left-2 mt-2">
           
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Number of Hall</span>
                  <h4>{{ all_data.no_of_hall ? all_data.no_of_hall : 0 }}</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-success fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
              </div>
            
          </b-col>


        <b-col md="4" class="border-left-2 mt-2">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Average  Annual Booking</span>
                  <h4><!-- {{ all_data.avg_book ?  all_data.avg_book : 0 }} 
                    {{ (all_data.avg_book1==0 || all_data.avg_book1==undefined  ? " " : ' - '+ all_data.avg_book1  )}} -->
                    {{  average_booking(all_data.avg_book ?  all_data.avg_book : 0, all_data.avg_book1==0 || all_data.avg_book1==undefined  ? 0 : all_data.avg_book1) }}
                  </h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-primary fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
              </div>
            
        </b-col>

        <b-col md="4">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Booking Value</span>
                  <h4> &#8377;<!-- {{ numberWithCommas(all_data.avg_amt) }} 
                    {{ (all_data.avg_amt1==0 || all_data.avg_amt1==undefined  ? " " : ' - '+ '&#8377;'+ numberWithCommas(all_data.avg_amt1)  )}} -->

                    {{ numberWithCommas(average_anual_income(all_data.avg_amt,all_data.avg_amt1==0 || all_data.avg_amt1==undefined  ? 0 : all_data.avg_amt1)) }}
                   </h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-primary fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
               
              </div>
            
              
        </b-col>
      </b-row>
      <b-modal id="modal-6" title="Update Other Details" centered header-bg-variant="primary" 
      header-text-variant="light" ok-title="Update" @ok="update_other_details" :ok-disabled="others_disable">
        <b-overlay :show="others_disable" rounded="sm" opacity="0.05">
        <b-form-group id="input-group-2"  label-for="input-2">
    <label for="input-live">Number of Hall:</label>
    <b-form-input
        id="input-live"
        v-model="avg_booking"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Number of Hall"
        trim
        >
    </b-form-input>
</b-form-group>
<b-form-group id="input-group-2"  label-for="input-2">
    <label for="input-live">Avearge Annual Booking:</label>
   
    <b-input-group  class="mb-2">
      <b-form-input
        id="input-live"
        v-model="monthly_booking"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Avearge Annual Booking"
        trim
        >
    </b-form-input>
    <!-- <b-form-input aria-label="Last name" readonly value="-" class="text-center w-5 input-texts"></b-form-input>
   
      <b-form-input
        id="input-live"
        v-model="monthly_booking_range"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Range Avearge Annual Booking"
        trim
        >
    </b-form-input> -->
  </b-input-group>
</b-form-group>
<b-form-group id="input-group-2"  label-for="input-2">
  
    <label for="input-live">Booking Value:</label>
   
    <b-input-group  class="mb-2">
      <b-form-input
        id="input-live"
        v-model="monthly_income"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Avearge Annual Income"
        trim
        >
    </b-form-input>
    <!-- <b-form-input aria-label="Last name" readonly value="-" class="text-center w-5 input-texts"></b-form-input>
   
      <b-form-input
        id="input-live"
        v-model="monthly_income1"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Avearge Annual Income"
        trim
        >
    </b-form-input -->
  </b-input-group>
</b-form-group>
<b-form-group id="input-group-2"  label-for="input-2">
<label for="input-live">Vendor Plan:</label>
<b-form-select v-model="vendor_plan" :options="vendorplan"></b-form-select>
</b-form-group>

<!-- <b-form-group id="input-group-2"  label-for="input-2">
    <label for="input-live">Amount of Plan:</label>
    <b-form-input
        id="input-live"
        v-model="amount_of_plan"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Amount of plan"
        trim
        >
    </b-form-input>
</b-form-group>
 -->

</b-overlay>
      </b-modal>
    </b-card>
      <b-row>
        <b-col md="12">
            <b-card  class="mb-3">
               <!--  <b-card-header header-tag="nav">
                    <div class="d-flex justify-content-between">
                        <h4>Rent & Capacity </h4>
                        <div>
                          <i class="mdi mdi-plus-circle cursor-pointer absolute-top fs-25"  @click="rent_capacity_add" ></i>
                           
                        </div>
                    </div>
  </b-card-header> -->
  <div  class="filterSection" >
<table class="table" >
  <tr><th colspan="3" class="border-top-none"><b class="border-b_">Rent & Capacity</b> <i class="mdi mdi-plus-circle cursor-pointer absolute-top fs-25 text-purple"  @click="rent_capacity_add" ></i></th></tr>
    <tr>
        <th class="w-25">Name</th>
        <th class="w-25">Type</th>
        <th class="w-25">Capacity</th>
        <th class="w-25">No of Booking</th>
        <th class="w-25">Price</th>
        <th class="w-5"></th>
    </tr>
    <tbody v-if="all_data.rent">
    <tr v-for="(rents,r) in JSON.parse(all_data.rent)" :key="r">
        <td>{{ rents.name }}</td>
        <td>{{ rent_capacity(rents.type) }}</td>
        <td>{{ rents.capacity_depand ? 'Depend on Capacity ' : rents.capacity ? (rents.capacity==0 ? 'nill': 

        rents.capacity  + ' ' + (rents.capacity1==0 || rents.capacity1==undefined  ? " " : ' - '+rents.capacity1  ) ) : 'nill'}}
        </td>
        <td> {{ rents.no_of_booking ? (rents.no_of_booking==0 ? 'nill': 
        rents.no_of_booking + ' ' + (rents.no_of_booking1==0 || rents.no_of_booking1==undefined  ? " " : ' - '+rents.no_of_booking1  )): 'nill' 
        }}</td>
        <td>{{ rents.price_depand ? 'Depend on Capacity ' : rents.price ? (rents.price==0 ? 'nill':
          '&#8377;'+ numberWithCommas(rents.price)) + ' ' + (rents.price1==0 || rents.price1==undefined  ? " " : ' - '+'&#8377;'+ numberWithCommas(rents.price1)  ): 'nill' 
          }}</td>
      
        <td  class="text-right"><i class="mdi mdi-pencil cursor-pointer text-purple" @click="rent_capacity_edit(r)"></i></td>
    </tr>
   </tbody>
   <tbody v-else>
    <tr>
      <td colspan="5">
        <br>
        <br>
        <h3 class="text-center text-purple">No Data Found</h3>
        <br>
        <br>
      </td>
    </tr>
    
  </tbody>
</table>
</div>
            </b-card>


         
            
            <b-modal id="modal-5" title="Rent & Capacity" centered header-bg-variant="primary" header-text-variant="light" 
            :ok-title="Add" @ok="rent_capacity_insert" :ok-disabled="rent_disable">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-overlay :show="rent_disable" rounded="sm" opacity="0.05">
      
              
              <b-form-group id="input-group-2"  label-for="input-2">
    <label for="input-live">Name:</label>
    <b-form-input
        id="input-live"
        v-model="$v.form.name.$model"
        :state="validateRentState('name')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Rent & capacity Venue name"
        trim
        >
    </b-form-input>
</b-form-group>
      <b-form-group id="input-group-2" label="Type" label-for="input-2">
        <b-form-select :options="options1"  v-model="$v.form.select_type.$model"
        :state="validateRentState('select_type')"></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-2"  label-for="input-2">
    <label for="input-live">No of Booking:</label>
  <!--   <b-form-input
        id="input-live"
        v-model="$v.form.no_of_booking.$model"
        :state="validateRentState('no_of_booking')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter No of Monthly Booking"
        trim
        >
    </b-form-input> -->
    <b-input-group  class="mb-2">
    <b-form-input
        id="input-live"
        v-model="$v.form.no_of_booking.$model"
        :state="validateRentState('no_of_booking')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter No of Annual Booking"
        trim
        >
    </b-form-input>
    <b-form-input aria-label="Last name" readonly value="-" class="text-center w-5 input-texts"></b-form-input>
   
      <b-form-input
        id="input-live"
        v-model="$v.form.no_of_booking1.$model"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter No of Annual Booking"
        trim
        :state="validateRentState('no_of_booking1')"
        >
    </b-form-input>
    </b-input-group>
</b-form-group>

      <b-form-group id="input-group-2" label="Price" label-for="input-2">
            <b-input-group size="md"  class="mb-2">
                <b-form-input aria-label="Small text input with custom switch"
               
                placeholder="Enter Price"
                :readonly="form.price_depand"
                v-model="$v.form.price.$model"
        :state="validateRentState('price')"
                ></b-form-input>

                <b-form-input aria-label="Small text input with custom switch"
               
               placeholder="Enter Price"
               :readonly="form.price_depand"
               v-model="$v.form.price1.$model"
       :state="validateRentState('price1')"
               ></b-form-input>

                <b-input-group-append is-text>
                <b-form-checkbox switch class="mr-n2 mb-n1" v-model="form.price_depand">
                    Depending On Capacity <span class="sr-only">Checkbox for previous text input</span>
                </b-form-checkbox>
                </b-input-group-append>
            </b-input-group>

      </b-form-group>

      <b-form-group id="input-group-2" label="Capacity" label-for="input-2">
            <b-input-group size="md"  class="mb-2">
                <b-form-input aria-label="Small text input with custom switch"
               
                placeholder="Enter Capacity"
                :readonly="form.capacity_depand"
                v-model="$v.form.capacity.$model"
        :state="validateRentState('capacity')"
                ></b-form-input>
                
                <b-form-input aria-label="Small text input with custom switch"
               
                placeholder="Enter Capacity"
                :readonly="form.capacity_depand"
                v-model="$v.form.capacity1.$model"
        :state="validateRentState('capacity1')"
                ></b-form-input>
                <b-input-group-append is-text>
                <b-form-checkbox switch class="mr-n2 mb-n1"  v-model="form.capacity_depand">
                    Depending On Capacity <span class="sr-only" >Checkbox for previous text input</span>
                </b-form-checkbox>
                </b-input-group-append>
            </b-input-group>
      </b-form-group>

    </b-overlay>

      
    </b-form>
  </b-modal>

        </b-col>
      
        <b-col md="12">
            <b-card  class=" mb-3 "  >
               <!--  <b-card-header header-tag="nav">
                    <div class="d-flex justify-content-between">
                        <h4>Remark </h4>
                        <div>
                          <i class="mdi mdi-plus-circle cursor-pointer absolute-top fs-25" v-b-modal.modal-1 ></i>
                        </div>
                    </div>
                
  </b-card-header> -->
  <div class="tableFixHeads filterSection1" ref="infoBox" >
    <i class="mdi mdi-plus-circle cursor-pointer absolute-top fs-25 text-purple" v-b-modal.modal-1 ></i>
  <table class="table" >
    <thead>
    <tr><th colspan="3" class="border-top-none"><b class="border-b_">Remark</b> </th></tr>
    <tr>
        <th class="w-25">Date </th><th class="w-50">Remark</th><th >Next  Appointment</th>
    </tr>
  </thead>
  <tbody v-if="all_data.remark">
    <tr v-for="(rema,r) in JSON.parse(all_data.remark)" :key="r">
        <td class="top_of_vertical">{{ date_format(rema.date) }} </td><td>
          <div class="d-flex remark_det">
            <span v-html="rema.remark"></span> &nbsp; <i class="mdi mdi-comment cursor-pointer" @click="add_comment_creates(r,'','comment')"></i>
          </div>
          
        <ul class="comment_list" v-if="rema.comments">
          <li v-for="(comment,c) in JSON.parse(rema.comments)" :key="c"> {{ comment.comment }}</li>
        </ul>
        
        </td>
        <td class="top_of_vertical"> {{ date_format(rema.appoinment_date) }}</td>
    </tr>
  </tbody>
  <tbody v-else>
    <tr>
      <td colspan="3">
        <br>
        <br>
        <h3 class="text-center text-purple">No Data Found</h3>
        <br>
        <br>
      </td>
    </tr>
   
  </tbody>
</table>
</div>
            </b-card>
        </b-col>
        </b-row>
        
        <b-modal id="comment-add" title="Comments" centered header-bg-variant="primary" 
        header-text-variant="light"  ok-title="Add Comment" @ok="save_comment" :ok-disabled="comment_disable">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-overlay :show="remark_disable" rounded="sm" opacity="0.05">
          <b-form-textarea
            id="textarea"
            v-model="$v.message_det.message.$model"
        :state="validateMessageState('message')"
            placeholder="Enter something..."
            rows="3"
            max-rows="6">
        </b-form-textarea>
        </b-overlay>
        </b-form>
        </b-modal>



        <b-modal id="modal-1" title="Remark & Appointment" centered header-bg-variant="primary" 
        header-text-variant="light" @ok="AddRemark" ok-title="Add Comment" :ok-disabled="remark_disable">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-overlay :show="remark_disable" rounded="sm" opacity="0.05">
      <b-form-group
        id="input-group-1"
        label="Remark"
        label-for="input-1"
        description="We'll never share your email with anyone else."
      >
        <vue-editor
        v-model="$v.remark_remark.remark.$model"
        :state="validateRemarkState('remark')"
        class="venue-custom-input"  :editor-toolbar="customToolbar"></vue-editor>

        
      </b-form-group>
      <span v-if="remark_remark.remark">
        <div v-if="!remark_remark.remark">
              Please enter Remark
            </div>
      </span>
      

      

      <b-form-group id="input-group-2" label="Next Appoinment Date" label-for="input-2">
        <b-form-input
          id="input-2"
          placeholder="Enter Next Appoinment"
          required
          type="date"
          v-model="$v.remark_remark.appoinment_date.$model"
        :state="validateRemarkState('appoinment_date')"
        ></b-form-input>
      </b-form-group>

    </b-overlay>
    </b-form>
  </b-modal>
        </b-col>
        <b-col md="4">
            <b-card  class=" mb-3" >
              <!--   <b-card-header header-tag="nav">
                <h4>Logs</h4>
  </b-card-header> -->
 <!--  <table class="table" v-if="all_data.log_details">
    <tr><th colspan="3" class="border-top-none"><b class="border-b_">Logs</b> </th></tr>
    <tr v-for="(log,l) in all_data.log_details" :key="l">
        <td> <span class="text-success">{{ log.username }}</span>  &nbsp;  &nbsp;  &nbsp; {{ log.message }}  &nbsp;  &nbsp;  &nbsp; {{ log.Time_log }} </td>
    </tr>
    
   
</table>
 -->
 <template #header>
								<div class="d-flex justify-content-between">
									<div><h4 class="mb-0 border-b_"> Logs</h4></div>
									
								</div>
								
							</template>
<b-list-group :style="{'height':height+'px'}" class="anyClass" v-if="all_data.log_details" >
								<div v-for="(log,l) in all_data.log_details" :key="l">
									<b-list-group-item href="#" class="flex-column align-items-start"  v-if="log.message">
										
										<div class="d-flex w-100 justify-content-between" v-if="log.message">
											<h6 class="mb-1"><i class="text-venuecolor">{{ log.username }}</i> - {{ log.log }}</h6>
											
										</div>
										{{log.message}}
										<small class="text-muted" v-if="log.message">{{log.Time_log}}</small>
										
									</b-list-group-item>
									
								</div>
							</b-list-group>
            </b-card>

        </b-col>
    </b-row>
  </div>
</template>


<script>
import axios from 'axios';

import { VueEditor } from "vue2-editor";
import {
		required,
		minLength,
		maxLength,
		numeric,
		integer,
		helpers,
	} from "vuelidate/lib/validators";
  import { validationMixin } from "vuelidate";


  const nameCheck = helpers.regex(
    "nameCheck",
    /^[a-z A-Z]+(?:-[a-z A-Z]+)*$/
	);	

  export default {
    mixins: [validationMixin],
    data() {
      return {
        vendor_disable:false,
        mobile_disable:false,
        rent_disable:false,
        remark_disable:false,
        others_disable:false,
        comment_disable:false,
        status_disable:false,
        lanch_disable:false,
        vendor_id:'',
        all_data:'',
        avg_booking:'',
        comment_id:'',
        monthly_booking_range:0,
        monthly_income1:0,
        height: '',
          width:'',
          selecttype:'+91',
          selecttype1:'+91',
          selected_status:'',
          expected_date:'1st Jan 1970',
          amount_of_plan: 0,
          vendor_plan: null,
vendorplan: [
          { value: null, text: 'Please select an option' },
          { value: 'Percentage', text: 'Percentage' },
          { value: 'Subscription', text: 'Subscription' }
        ],
        form: {
          id: '',
          email: '',
          name: '',
          food: null,
          checked: [],
          select_type:null,
          no_of_booking:null,
          no_of_booking1:null,
            price:null,
            capacity:null,
            capacity_depand:false,
            price_depand:false,
           
            okDisabled: true,
           
        },
        remark_remark:
        {
          appoinment_date:'',
          remark:'',
        },
        contact:
        {
          name:null,
          number:null,
          id:null,
          landnumber:null,
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true,
        selected: '0',
        options: [
          { value: null, text: 'Please select an option' },
          { value: '0', text: 'Not Contacted' },
          { value: '1', text: 'Attempted Contact' },
          { value: '2', text: 'Contacted' },
          { value: '3', text: 'Converted' },
          { value: '4', text: 'Cancelled' },
        ],
       
        options1: [
          { value: null, text: 'Please select an option' },
          { value: '1', text: 'In-door Big Hall' },
          { value: '2', text: 'In-door Small Hall' },
          { value: '3', text: 'Out-door Big Venue' },
          { value: '4', text: 'Out-door Small Venue' },
          { value: '5', text: 'Others' },
        ],
        customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image", "code-block"]
    ],
    vendor:
    {
      name:'',
      mob:'',
      place:'',
      location:'',
      pincode:'',
      landmob:''
      
    },
    message_det:
        {
          message:''
        }
      }
      
    },
    validations: {
			contact: {
				name: {
					required,
					nameCheck,
				},
				number: {
					required,
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10),
				},
        landnumber:{
            required,
            numeric,
            
            minLength: minLength(11),
            maxLength: maxLength(11),
          },
			},
      form:
      {
        select_type:{
					required,
				},
        name:{
					required,
          nameCheck
				},
        price:
        {
          integer,
        },
        price1:
        {
          integer,
        },
        capacity:
        {
          integer,
        },
        capacity1:
        {
          integer,
        },
        no_of_booking:
        {
          required,
          integer,
        },
        no_of_booking1:
        {
          required,
          integer,
        }
      },
      remark_remark:
        {
          appoinment_date:{
					required,
				},
        remark:
        {
          required,
        },
         
        },
        message_det:
        {
          message:
          {
            required
          }
        },
        vendor:
        {
          name:{
            required,
            nameCheck
          },
          mob:{
            required,
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10),
          }, 
          landmob:{
            required,
					numeric,
					minLength: minLength(11),
					maxLength: maxLength(11),
          },
          place:{
            required
          },
          location:{
            required
          },
          pincode:{
            required,
            numeric,
					minLength: minLength(6),
					maxLength: maxLength(6),
          },
          
        },
      
        
    },
    components: { VueEditor },
    methods: {
      /* Mobile_or_land()
      {
alert(this.selecttype)
      }, */
      validateState(name) {
				const { $dirty, $error } = this.$v.contact[name];
				return $dirty ? !$error : null;
			},
      validateRentState(name) {
				const { $dirty, $error } = this.$v.form[name];
				return $dirty ? !$error : null;
			},
      validateRemarkState(name) {
				const { $dirty, $error } = this.$v.remark_remark[name];
				return $dirty ? !$error : null;
			}, 
      validateMessageState(name) {
				const { $dirty, $error } = this.$v.message_det[name];
				return $dirty ? !$error : null;
			}, 
      validatevendorState(name) {
				const { $dirty, $error } = this.$v.vendor[name];
				return $dirty ? !$error : null;
			},
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      today_date()
      {
        var myDate = new Date();
        var d = new Date(myDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        
        if (month.length < 2) 
        month = '0' + month;
        if (day.length < 2) 
        day = '0' + day;
        return [year,month,day].join('-');
      },
      MobileAdd()
      {
        this.contact.name="";
        this.contact.number="";
        this.contact.id="";
        this.$bvModal.show("modal-mobile");
      },
      vendors_details() {
				
				axios
				.post("/admin/get_all_vendor_detail", {
					vendor_id: this.vendor_id,
				})
				.then((resp) => {
                    this.all_data = resp.data;
                    this.selected = resp.data.status;
                    this.expected_date = resp.data.expected_date;
                    setTimeout(() => {
                      this.getHeight();
                    }, 500);
                   
                })
            },

      newmobile_number(bvModalEvent)
			{
        bvModalEvent.preventDefault();
        this.selecttype=='+91' ? this.contact.landnumber = "00000000000" : this.contact.number = "0000000000";
        
        this.$v.contact.$touch();
				if (this.$v.contact.$anyError) {
					return;
					} else {
					
           this.mobile_disable =true;
				
			
				axios.post("/admin/new_mobile_create",
				{
					name:this.contact.name,
					//number:this.contact.number,
					number:this.selecttype=='+91' ?  this.contact.number : this.contact.landnumber,
					selecttype:this.selecttype,
					id:this.contact.id,
					vendor_id : this.vendor_id 
				})
				.then((resp) => {
          this.mobile_disable =false;
					resp;
          this.contact.name="";
          this.contact.number="";
          this.contact.id="";
          this.selecttype="";
					this.vendors_details();
          this.$bvModal.hide("modal-mobile");
				});
     
    }
			},
      editMobile(edit_id)
      {
        var mobile = JSON.parse(this.all_data.contact_det)[edit_id];
        this.contact.name=mobile.name;
        //this.contact.number=mobile.mob;
        this.contact.number=mobile.country_code=='+91' ?  mobile.mob : '';
        this.contact.landnumber=mobile.country_code!='+91' ?  mobile.mob : '';
        this.selecttype=mobile.country_code=='+91' ?  mobile.country_code : '';
        this.contact.id=edit_id;
      },
      status_change()
      {
        this.status_disable=true;
        axios.post("/admin/status_change",
				{
					status:this.selected,
					vendor_id : this.vendor_id 
				})
				.then((resp) => {
          this.status_disable=false;
					resp;
					this.vendors_details();
				});
      },
      status_change_value(status)
      {
         return status == 0 ? "Not Contacted" :  status == 1 ? "Attempted Contact"  :  status == 2 ? "Contacted" :  status == 3 ? "Converted" : status == 4 ? "Cancelled":"";
      },
    
      vendor_edit()
      {
        this.$bvModal.show("modal-edit");

        this.vendor.name=this.all_data.name;
        this.vendor.mob=this.all_data.mob;
        this.vendor.place=this.all_data.place;
        this.vendor.location=this.all_data.location;
        this.vendor.pincode=this.all_data.pincode;
       
        
      },
      Vendor_update(bvModalEvent)
      {
        bvModalEvent.preventDefault();
        this.selecttype1=='+91' ? this.vendor.landmob = "00000000000" : this.vendor.mob = "0000000000";
        this.$v.vendor.$touch();
				if (this.$v.vendor.$anyError) {
					return;
					} else {
           
          this.vendor_disable=true;
        axios.post("/admin/Vendor_update",
				{
					name:this.vendor.name,
					//number:this.vendor.mob,
					number:this.selecttype1=='+91' ?  this.vendor.mob : this.vendor.landmob,
					place:this.vendor.place,
					location:this.vendor.location,
					pincode:this.vendor.pincode,
					vendor_id : this.vendor_id,
					selecttype : this.selecttype1
				})
				.then((resp) => {
          this.vendor_disable=false;
					resp;
          this.vendor.name='';
          this.vendor.mob='';
          this.vendor.place='';
          this.vendor.location='';
          this.vendor.pincode='';
          this.selecttype='';
					this.vendors_details();
          this.$bvModal.hide("modal-edit");
				});
       
      
    }
      },
      rent_capacity(rnt)
      {
return rnt==1 ? 'In-door Big Hall' :  rnt==2 ?   'In-door Small Hall' : rnt==3 ? 'Out-door Big Venue' : rnt==4 ? 'Out-door Small Venue' : 'Others';
      }, 
      load_source(src)
      {
          return src==1 ? 'Web' :  src==2 ?   'Phone Enquiry' : src==3 ? 'Partner Referral' : src==4 ? 'Purchased List' : 'Other';
      },
      rent_capacity_add()
      {
        this.form.id='',
        this.form.select_type='';
          this.form.price='';
          this.form.capacity='';
          this.form.no_of_booking='';
          this.form.price_depand=false;
          this.form.capacity_depand=false;
        this.$bvModal.show("modal-5");
      },
      rent_capacity_insert(bvModalEvent)
			{
        bvModalEvent.preventDefault();

        this.$v.form.$touch();
				if (this.$v.form.$anyError) {
					return;
					} else {
					
        this.rent_disable=true;
           
        axios.post("/admin/rent_capacity_insert",
				{
					select_type:this.form.select_type,
					price:this.form.price,
					price1:this.form.price1,
					name:this.form.name,
					capacity:this.form.capacity,
					capacity1:this.form.capacity1,
					price_depand:this.form.price_depand,
					capacity_depand:this.form.capacity_depand,
					id:this.form.id,
					no_of_booking:this.form.no_of_booking,
					no_of_booking1:this.form.no_of_booking1,
					vendor_id : this.vendor_id,
				})
				.then((resp) => {
          this.rent_disable=false;
					resp;
          this.form.select_type='';
          this.form.price='';
          this.form.price1='';
          this.form.name='';
          this.form.capacity='';
          this.form.capacity1='';
          this.form.no_of_booking='';
          this.form.no_of_booking1='';
          this.form.price_depand=false;
          this.form.capacity_depand=false;
					this.vendors_details();
          this.$bvModal.hide("modal-5");
				});
        
      }
      },
      rent_capacity_edit(edit_id)
      {
        this.$bvModal.show("modal-5");
        var capacity = JSON.parse(this.all_data.rent)[edit_id];
        this.form.select_type=capacity.type;
        this.form.price=capacity.price;
        this.form.price1=capacity.price1;
        this.form.capacity=capacity.capacity;
        this.form.capacity1=capacity.capacity1;
        this.form.price_depand=capacity.price_depand;
        this.form.capacity_depand=capacity.capacity_depand;
        this.form.no_of_booking=capacity.no_of_booking;
        this.form.no_of_booking1=capacity.no_of_booking1;
        this.form.name=capacity.name;
        this.form.id=edit_id;
      },
      AddRemark(bvModalEvent)
      {
        
        bvModalEvent.preventDefault();

this.$v.remark_remark.$touch();
if (this.$v.remark_remark.$anyError) {
  return;
  } else {
    this.remark_disable=true;
        axios.post("/admin/add_remark_insert",
				{
					appoinment_date:this.remark_remark.appoinment_date,
					remark:this.remark_remark.remark,
					vendor_id : this.vendor_id 
				})
				.then((resp) => {
          this.remark_disable=false;
					resp;
          this.remark_remark.appoinment_date='';
          this.remark_remark.remark='';
					this.vendors_details();
          this.$bvModal.hide("modal-1");
				});
     
      }
      },
      add_comment_creates(comment_id,id,comment)
      {
        this.comment_id=comment_id;
        id,comment
        this.$bvModal.show("comment-add");
      },
      save_comment(bvModalEvent)
      {
        bvModalEvent.preventDefault();

this.$v.message_det.$touch();
if (this.$v.message_det.$anyError) {
  return;
  } else {
    this.comment_disable=true;
        axios.post("/admin/new_comment_save",
				{
					comment_id:this.comment_id,
					message:this.message_det.message,
					vendor_id : this.vendor_id 
				})
				.then((resp) => {
          this.comment_disable=false;
					resp
					this.message_det.message="";
          this.vendors_details();
          this.$bvModal.hide("comment-add");
				});
     
    }
      },
      date_format(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
				
			},
            getDateOrdinal(date) {
                let day = date.getDate();
                if (day > 3 && day < 21) return 'th';
                switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
    
      getHeight() {
 
 var divElement = document.querySelector(".filterSection");
 var divElement1 = document.querySelector(".filterSection1");

 var elemHeight = divElement.offsetHeight;
 var elemHeight1 = divElement1.offsetHeight;
 //alert(elemHeight+elemHeight1)

 this.height = elemHeight+elemHeight1 + 120 ;
},
hall_other_details()
{
  this.$bvModal.show("modal-6");
    this.avg_booking=this.all_data.no_of_hall;
    this.monthly_booking=this.all_data.avg_book;
    this.monthly_income=this.all_data.avg_amt;
    this.monthly_income1=this.all_data.avg_amt1;
    this.monthly_booking_range=this.all_data.avg_book1;
    this.amount_of_plan=this.all_data.amount_of_plan;
    this.vendor_plan=this.all_data.vendor_plan;
},
update_other_details()
{
  this.others_disable=true;
  axios.post("/admin/update_other_details",
				{
					avg_booking:this.avg_booking,
					monthly_booking:this.monthly_booking,
					monthly_booking_range:this.monthly_booking_range,
					monthly_income:this.monthly_income,
					monthly_income1:this.monthly_income1,
          amount_of_plan:this.amount_of_plan,
          vendor_plan:this.vendor_plan,
					vendor_id : this.vendor_id 
				})
				.then((resp) => {
					resp
          this.others_disable=false;
          this.vendors_details();
          this.$bvModal.hide("modal-6");
				});
},
Expected_launch_update()
{
      this.lanch_disable=true;
      axios.post("/admin/Expected_launch_update",
				{
					expected_date:this.expected_date,
          vendor_id : this.vendor_id 
				})
				.then((resp) => {
					resp
          this.lanch_disable=false;
          this.vendors_details();
          this.$bvModal.hide("modal-4");
				});
},
numberWithCommas(x) {
  var num = x ? x : 0;
				return num.toLocaleString('en-IN')
        },
        nameSelect(event)
        {
          if(this.contact.name=='no name')
          {
            return event.target.select()
          }
          
        },
        convert_amount(price)
        {
          if(price)
          {
            var range = price.split('-');
          return '&#8377;'+this.numberWithCommas(parseFloat(range[0]))+'- &#8377;'+this.numberWithCommas(parseFloat(range[1]));
          }
         
        },
        average_booking(book,book1)
        {
          return parseInt(book) + parseInt(book1) / 2;
        },
        average_anual_income(amt,amt1)
        {
          var amts =  parseFloat(amt) + parseFloat(amt1) ;
          return amts/2;
        }

    },
    mounted()
    {
        this.vendor_id = this.$route.params.id;
        this.vendors_details();
       
        /* 
          const element = this.$refs.infoBox
          this.height = element.offsetHeight
          this.width = element.offsetWidth */
       
    },
    computed: {
      /* mobileState() {
        return this.contact.number > 9874563210 && this.contact.number < 9874563210 ? true : false
      } */
    },
  }
</script>

<style>
.anyClass {
	overflow-y: scroll;
	}
	div .anyClass {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll; 
	}
	.top_of_vertical
  {
    vertical-align: top !important;
  }
  .comment_list
  {
    color:gray;
    font-size:12px;
  }

  .absolute-top
  {
    position: absolute;
    top: 0;
    right: 5px;
  } 
  
  .absolute-top-1
  {
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .fs-25
  {
    font-size: 25px;
  }
  .status_color {
    background: rgba(119,11,209,.7019607843137254);
    color: #fff;
}
.border-b-2
	{
	border:1px solid white !important;
	}
  .border-b {
    border-bottom: 5px solid rgba(119,11,209,.7019607843137254);
}
.border-b_ {
    border-bottom: 2px solid rgba(119,11,209,.7019607843137254);
}
.border-top-none
{
  border-top: none !important;
}

.tableFixHead          { overflow-y: auto; height: 300px; }
.tableFixHead thead th {position: sticky;  top: 0; }
.tableFixHead th { background: white; }

.border-left-2
{
  border-right:2px solid rgba(119,11,209,.7019607843137254);;
}
.border-top-2
{
  border-top:2px solid rgba(119,11,209,.7019607843137254);;
}
.text-purple
{
  color: rgba(119,11,209,.7019607843137254);;
}
.top-side-status
{
  position: absolute;
    top: 0;
    left: -4px;
    background: white;
    padding: 3px 10px 3px 7px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
}
.absolute-top-status {
    position: absolute;
    top: -13px;
    right: -10px;
    font-size: x-large;
}
.absolute-top-status1 {
  position: absolute;
    top: -7px;
    right: 5px;
    font-size: x-large;
}
.border-right-light
{
  border-right:1px solid white;
}
.input-texts
{
  background: #f3f1f1 !important;
  color: #040404 !important;
}
.remark_det p {
  margin-bottom: 0rem;
};
</style>